<template>
  <ThePopup title="RECURRING DONATIONS">
    <!-- WIZARD STEPS -->
    <template v-slot:header>
      <TheWizardSteps />
    </template>
    <!-- END WIZARD STEPS -->
    <div class="col-12 above white_bg">
      <div class="row mx-0 justify-content-center">
        <div class="col-12 col-md-8 mb-2">
          <div class="row mx-0 mt-4 justify-content-center" >
            <div class="col-12 p-0">
              <div class="row mx-0 justify-content-center">
                <div class="col-12 text-center medium">
                  I would like to donate the following as a recurring donation:
                </div>
                <div class="col-12 mb-3"  >
                  <div class="row mx-0 my-3 justify-content-center medium bigger_font">
                    <div class="col-11 col-md-10 p-0">
                      <div class="row m-0">
                        <div class="col-12 p-0">
                          <CurrencyFieldCenter placeholder="0.00" size="big" color="transparent" v-model="value.amount" :currencySymbol="defaultCurrency" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row m-0 justify-content-center">
                    <div class="col-12 col-md-10 col-lg-8 p-0">
                      <div class="row m-0">
                        <div class="col-12 p-0 mb-3">
                          <SelectAll
                            v-model="selectedParentProject"
                            id="project"
                            :options="parentProjects"
                            placeholder="Projects"
                            displayValue="description"
                            :isSearch="true"
                            color="gold"
                            :goUp="true"
                          />
                        </div>
                        <div class="col-12 p-0 mb-3" v-if="Object.keys(selectedParentProject).length !== 0 && childProjects.length > 0">
                          <SelectAll
                            v-model="selectedChildProject"
                            id="project"
                            :options="childProjects"
                            placeholder="Children Projects"
                            displayValue="description"
                            color="gold"
                            :autoSelect="true"
                            :isSearch="true"
                            :goUp="true"
                          />
                        </div>
                        <div class="col-12 p-0 mb-3">
                          <SelectAll
                            v-if="Object.keys(selectedProject).length !== 0 && Object.keys(selectedProject.projectDonationTypeMaps).length > 0"
                            v-model="donationType"
                            id="project"
                            :options="selectedProject.projectDonationTypeMaps"
                            placeholder="Donation Type"
                            displayValue="donationType"
                            subDisplayValue="description"
                            color="gold"
                            :autoSelect="true"
                            :goUp="true"
                          />
                          <Select
                            v-else
                            v-model="donationType"
                            id="project"
                            :options="selectedProject.projectDonationTypeMaps"
                            placeholder="Donation Type"
                            displayValue="donationType"
                            subDisplayValue="description"
                            color="gold"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 p-0 align-self-end above">
      <div class="row m-0 background-green py-3 justify-content-center">
        <div class="col-12 px-0">
          <div class="row mx-0 justify-content-center">
            <div class="col-auto" >
              <Button color="green_light" width="100" btnText="Back" icon2="arrow" @buttonClicked="back">
                <template v-slot:iconStart>
                  <IconArrowBack color="white" />
                </template>
              </Button>
            </div>
            <div class="col-auto">
              <Button color="green_light" width="100" btnText="Setup Recurring Donation" icon="arrow" @buttonClicked="setupRecurring" :disabled="isDisabled">
                <IconArrowForward color="white" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ThePopup>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    Select: defineAsyncComponent(() => import('@/components/SelectProject.vue')),
    CurrencyFieldCenter: defineAsyncComponent(() => import('@/components/CurrencyFieldCenter.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconArrowBack: defineAsyncComponent(() => import('@/components/icons/IconArrowBack.vue')),
    TheWizardSteps: defineAsyncComponent(() => import('../components/TheWizardSteps.vue')),
    ThePopup: defineAsyncComponent(() => import('../components/ThePopup.vue'))
  },
  name: 'StartNew',
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue', 'setDonation'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      selectedProject: {},
      selectedParentProject: {},
      selectedChildProject: {},
      donationType: ''
    }
  },
  watch: {
    selectedProject () {
      if (this.selectedProject && this.selectedProject.projectID) {
        this.value.projectId = this.selectedProject.projectID
      }
    },
    selectedParentProject () {
      if (this.selectedParentProject && Object.keys(this.selectedParentProject).length > 0) {
        if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
          this.selectedChildProject = {}
          this.selectedProject = this.selectedParentProject
        } else {
          this.selectedProject = this.selectedParentProject
        }
      }
    },
    selectedChildProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        this.selectedProject = this.selectedChildProject
      }
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects'
    ]),
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    isDisabled () {
      if (!this.value.amount || this.value.amount < 1) {
        return true
      } else if (!this.value.projectId) {
        return true
      } else {
        return false
      }
    },
    parentProjects () {
      return this.adminProjects.filter(project => {
        if (project.parentProjectID !== 0) {
          return false
        }
        return true
      })
    },
    childProjects () {
      const arr = this.adminProjects.filter(project => {
        if (this.selectedParentProject && project.parentProjectID !== this.selectedParentProject.projectID) {
          return false
        }
        return true
      })
      arr.sort((a, b) => (a.description > b.description ? 1 : -1))
      return arr
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    back () {
      this.$emit('back')
    },
    setupRecurring () {
      this.value.donationTypes = []
      this.selectedProject.projectDonationTypeMaps.forEach((dType) => {
        const obj = { type: dType, amount: null }
        if (dType !== this.donationType) {
          this.value.donationTypes.push(obj)
        } else {
          this.value.donationTypes.push({ type: this.donationType, amount: this.value.amount })
        }
      })
      console.log('setupRecurring', this.value)
      this.$emit('setDonation')
    }
  }
}

</script>

<style scoped>
.bigger_font {
  font-size: 20px;
}
.above {
  z-index: inherit;
}
.background-green {
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}
.wizard_step {
  border-radius: 50%;
  color: var(--green-color-dark);
  background-color: var(--gold-color);
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 0%);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 11px;
  width: 30px;
  height: 30px;
}
.wizard_step.active {
  background-color: var(--green-color-dark);
  color: var(--gold-color);
}
.wizard_divider {
  border-bottom: 1px solid rgba(104, 103, 103, 0.4);
  width: 15px;
  height: 16px;
}

</style>
